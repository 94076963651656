import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row, Col, theme, Modal, Button, Space } from 'antd';
import PulseLoader from 'react-spinners/PulseLoader';
import BroadcastMsg from '../popup/BroadcastMsg';
import SupportDocuments from '../support-document/SupportDocument';
import Cookies from 'js-cookie';
import moment from 'moment';
import { isLogged } from 'helpers/utils';
import PropTypes from 'prop-types';
import HelpDesk from 'modules/helpdesk/helpDesk';

const Footer = (props) => {
  const [visible, setVisible] = useState(false);
  const [visibleHelpDesk, setVisibleHelpDesk] = useState(false);
  const [message, setMessage] = useState('');
  const [date, setDate] = useState(null);

  const {
    token: { colorText },
  } = theme.useToken();

  const { broadcast, defaultAccess, portalVersion, hostName } = props;
  const handleVisiblity = () => setVisibleHelpDesk(false);

  useEffect(() => {
    if (broadcast) {
      broadcast.map((data) => {
        if (
          data.flag &&
          Cookies.get(`${defaultAccess}-broadcast`) == undefined
        ) {
          Cookies.set(`${defaultAccess}-broadcast`, defaultAccess, {
            expires: data.timeInterval / 36000,
          });
          setVisible(true);
          setMessage(data.message);
        }
      });
    }
  }, [broadcast, defaultAccess]);

  const tick = () => {
    setDate(moment().utc(true).format('DD/MM/YYYY hh:mm:ss'));
  };

  useEffect(() => {
    let timer = setInterval(tick, 1000);
    return () => clearInterval(timer);
  }, []);

  const getVersion = () =>
    portalVersion ? ` - Version ${portalVersion}` : null;

  const getDate = () => date || '';

  return (
    <Row>
      <Col span={24}>
        <Layout.Footer style={{ color: colorText }}>
          <Row>
            <Col
              xs={{ span: 24 }}
              md={{ span: 6 }}
              style={{ textAlign: 'left' }}>
              <div className='footer-font-size'>
                {(() => {
                  if (isLogged()) {
                    return `${getDate()}${getVersion()}`;
                  }
                })()}
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 9 }}
              style={{ textAlign: 'center' }}>
              <div className='copyright footer-font-size'>
                {props.isLoading && (
                  <PulseLoader size={6} color={'#FFFFFF'} loading={true} />
                )}
                {props.copyRights ? props.copyRights : null}
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 4 }}
              style={{ textAlign: 'center' }}>
              {defaultAccess !== 'SSP' && <SupportDocuments />}
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 5 }}
              style={{ textAlign: 'center' }}>
              <div className='footer-font-size'>
                <Link onClick={() => setVisibleHelpDesk(!visibleHelpDesk)}>
                  {' '}
                  Help Desk
                </Link>
                <Modal
                  style={{ position: 'relative', top: '7%' }}
                  title={
                    <div style={{ color: '#ef373e' }}>
                      {' '}
                      {isLogged()
                        ? `Sanmina Supplier Portal Help Desk Service Request entry`
                        : `Partner Portal Training Issues Only`}
                    </div>
                  }
                  footer={[
                    <>
                      <Row>
                        <Col span={20} offset={2}>
                          <div
                            style={{
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: 12,
                            }}>
                            {' '}
                            For the status of an open Service Request, please
                            contact the Sanmina Help Desk at +1 256 880 4883
                          </div>
                        </Col>
                      </Row>
                    </>,
                  ]}
                  width='1200px'
                  open={visibleHelpDesk}
                  onCancel={handleVisiblity}>
                  <div
                    style={{
                      height: '410px',
                      overflowY: 'auto',
                      marginTop: '0px',
                    }}>
                    <HelpDesk />
                  </div>
                </Modal>
              </div>
            </Col>
          </Row>
          <BroadcastMsg
            visibility={visible}
            broadcastMsg={message}
            handleVisiblity={(data) => setVisible(data)}
          />
        </Layout.Footer>
      </Col>
    </Row>
  );
};

Footer.propTypes = {
  broadcast: PropTypes.array,
  defaultAccess: PropTypes.string,
  portalVersion: PropTypes.string,
  hostName: PropTypes.string,
  isLoading: PropTypes.bool,
  copyRights: PropTypes.string,
};

export default Footer;
