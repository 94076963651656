/**
 * Created this file to Download ACP excel template using "Excel Download" menu option.
 */
/* Importing supports files  */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Radio, Space, Form } from 'antd';
import PropTypes from 'prop-types';
const RadioGroup = Radio.Group;

/* Defining the Api service call to fetch the details of the download excel templates based on the user access */
const ExcelTemplate = (props) => {
  const [value, setValue] = useState('');

  const onChangeRadio = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    const templates = props.data.excelTemplate;
    if (props.visible) {
      setValue(templates[0].value);
    }
  }, [props.visible]);

  // Created the below function to hide the Download excel template popup
  const handleCancel = () => {
    props.handlevisiblity(false);
  };

  return (
    <Modal
      title='Download Excel Template'
      open={props.visible}
      onCancel={() => props.handlevisiblity(false)}
      footer={[
        <a
          key='any-name'
          href={value}
          target='_blank'
          style={{ marginRight: 5 }}>
          <Button key='submit' type='primary'>
            Download
          </Button>
        </a>,
        <Button key='submitCancel' type='default' onClick={handleCancel}>
          Cancel
        </Button>,
      ]}>
      {/* Here we used the map method to parse the json objects into the form components to display the options in the radio button */}
      <Form>
        {props.data?.excelTemplate?.map((excelTemplate) => {
          return (
            <RadioGroup
              key={excelTemplate.value}
              onChange={onChangeRadio}
              value={value}
              style={{ inline: 'flex' }}>
              <Space direction='vertical'>
                <Radio
                  value={excelTemplate.value}
                  style={{
                    width: 700,
                    marginInlineStart: 10,
                  }}>
                  {excelTemplate.label}
                </Radio>
              </Space>
            </RadioGroup>
          );
        })}
      </Form>
    </Modal>
  );
};

ExcelTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  handlevisiblity: PropTypes.func,
};

export default ExcelTemplate;
