import store from '../../../../redux/store';
import service from '../../../../service/Service';
import {
  getLastRowIndex,
  getUUID,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../helpers/utils';
import { message, Modal, Table } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import selectColumn from '../../../common/ag-grid-select-column';
import Cookie from 'js-cookie';
import { exportDTo, getMassDownloadGridParams } from 'helpers/export';
export const ORDER_LINES_COLUMNS = 'cds/order-lines/ORDER_LINES_COLUMNS';
export const ORDER_LINES_COLUMNS_SUCCESS =
  'cds/order-lines/ORDER_LINES_COLUMNS_SUCCESS';
export const ORDER_LINES_COLUMNS_FAIL =
  'cds/order-lines/ORDER_LINES_COLUMNS_FAIL';
export const ORDER_LINES_ROWS_INFO = 'cds/order-lines/ORDER_LINES_ROWS_INFO';
export const ORDER_LINES_REFRESH_SUCCESS =
  'cds/order-lines/ORDER_LINES_REFRESH_SUCCESS';

const initialState = {
  newTab0: {
    loading: false,
    rowsInfo: {
      endRow: 0,
      lastRow: 0,
    },
    orderLinesColumns: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ORDER_LINES_COLUMNS:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], loading: true },
      };
    case ORDER_LINES_REFRESH_SUCCESS:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], loading: false },
      };
    case ORDER_LINES_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          orderLinesColumns: action.payload,
          loaded: true,
        },
      };
    case ORDER_LINES_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
          loaded: true,
        },
      };
    case ORDER_LINES_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          rowsInfo: action.payload,
        },
      };
    default:
      return state;
  }
}

const getColumnDefUpdated = (colDef) => {
  let formColDef = [];
  colDef.map((data) => {
    if (['header_id', 'line_id', 'shipment_track_flag'].includes(data.field)) {
      formColDef.push({
        ...data,
        lockVisible: true,
      });
    } else {
      formColDef.push({
        ...data,
      });
    }
  });
  return formColDef;
};

function getOrderLinesColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: ORDER_LINES_COLUMNS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `ORDER_STATUS_LINE_V`,
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/CDS/GET_ORDER_STATUS_LINE_V_COLUMNS`,
          currentTime
        );
        const { data } = response;
        if (data && data.statusCode === '200') {
          let configs = data.result;
          configs.columnDefs.unshift({
            ...selectColumn,
          });
          let getColDefLatest = getColumnDefUpdated(configs.columnDefs);
          let dataLoad = { ...configs, columnDefs: getColDefLatest };
          dispatch({
            type: ORDER_LINES_COLUMNS_SUCCESS,
            payload: dataLoad,
            tabKey,
          });
        } else {
          dispatch({
            type: ORDER_LINES_COLUMNS_FAIL,
            payload: 'Error in loading fc form!',
            tabKey,
          });
        }
      });
  };
}

function getOrderLinesRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: `ORDER_STATUS_LINE_V`,
              gridParams: { ...params.request },
              details: {
                email: Cookie.get('email'),
                screen: 'orders',
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CDS/GET_ORDER_STATUS_LINE_V_ROWS`,
            currentTime
          );
          const { data } = response;
          if (data && data.statusCode === '200') {
            params.success({
              rowData: data.result.rowData,
              rowCount: getLastRowIndex(params.request, data.result.rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: ORDER_LINES_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.result.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: Cookie.get('email'),
              details: {
                email: Cookie.get('email'),
                screen: 'orders',
              },
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: Cookie.get('email'),
                screen: 'orders',
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CDS/GET_${view}_MASS_DOWNLOAD_REPORT`,
            currentTime
          );
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              exportDTo({
                columnApi,
                fileName: fileName,
                data: result.rowData,
                exportType,
              }) && cb(true);
            }
          }
          return response;
        });
    }
  };
}

function prepareAndSubmitData(nodes) {
  let lineDetails = [];
  nodes.map(({ data }) => {
    lineDetails.push({
      order_number: data.order_number,
      line_number: data.line_number,
      header_id: data.header_id,
      line_id: data.line_id,
      waybill_number: data.clean_waybill,
      carrier_service_details: data.carrier_service_details,
    });
  });

  return (_dispatch, _getState, { api }) => {
    return api
      .post('cdssubmitaction', {
        TXN: {
          Header: {
            txn_type: 'ORDER_SHIPMENT_TRACKING',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          lineDetailsArray: lineDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        if (data) {
          if (data.returnCode === '1' || data.returnCode === '0') {
            const failureResponse = data.CommitPoArray;
            const dataSource = [];
            const columns = [
              {
                title: 'Order No',
                dataIndex: 'order',
                key: 'order',
              },
              {
                title: 'Line No',
                dataIndex: 'line',
                key: 'line',
              },
              {
                title: 'Tracking Status',
                dataIndex: 'shipment_tracking_status',
                key: 'shipment_tracking_status',
              },
              {
                title: 'Message',
                dataIndex: 'message',
                key: 'message',
              },
            ];
            failureResponse.map((item) => {
              dataSource.push({
                order: item.order_number,
                line: item.line_number,
                shipment_tracking_status: item.shipment_tracking_status,
                message: item.error_message,
              });
            });
            Modal.success({
              title: 'Data Refresh Status!',
              icon: <ExclamationCircleOutlined />,
              width: 800,
              content: (
                <div
                  style={{
                    maxHeight: 900,
                    overflowY: 'auto',
                  }}>
                  <div style={{ borderBottom: '1px solid #CCC', padding: 1 }}>
                    Line Item(s)
                  </div>
                  <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    width={700}
                  />
                </div>
              ),
            });
          } else {
            message.error('Something went wrong. Please try again!');
          }
        } else {
          message.error('Something went wrong. Please try again!');
        }
        return data;
      });
  };
}

export { getOrderLinesColumns, getOrderLinesRows, prepareAndSubmitData };
