import { message } from 'antd';
import Cookies from 'js-cookie';
import { setSearchCloseProps } from 'modules/pc/search/redux/search';
const CREATE_TAB = 'layout/CREATE_TAB';
const CREATE_TAB_SUCCESS = 'layout/CREATE_TAB_SUCCESS';
const CREATE_TAB_FAIL = 'layout/CREATE_TAB_FAIL';
const SWITCH_TAB = 'layout/SWITCH_TAB';
const SWITCH_TAB_SUCCESS = 'layout/SWITCH_TAB_SUCCESS';
const SWITCH_TAB_FAIL = 'layout/SWITCH_TAB_FAIL';
const REMOVE_TAB = 'layout/REMOVE_TAB';
const REMOVE_TAB_SUCCESS = 'layout/REMOVE_TAB_SUCCESS';
const REMOVE_TAB_FAIL = 'layout/REMOVE_TAB_FAIL';
const SIDER = 'layout/SIDER';
const SIDER_SUCCESS = 'layout/SIDER_SUCCESS';
const SIDER_FAIL = 'layout/SIDER_FAIL';
const CLEAR_ALL_TABS = 'layout/CLEAR_ALL_TABS';
const SWITCH_CONTENT_LAYOUT_SUCCESS = 'layout/SWITCH_CONTENT_LAYOUT_SUCCESS';
const SET_NAVIGATE_OBJECT_SUCCESS = 'layout/SET_NAVIGATE_OBJECT_SUCCESS';
const ADD_SIDER_OPTION_SUCCESS = 'layout/ADD_SIDER_OPTION_SUCCESS';
const REMOVE_SIDER_OPTION_SUCCESS = 'layout/REMOVE_SIDER_OPTION_SUCCESS';
const SET_GRID_EDIT_COUNT = 'layout/SET_GRID_EDIT_COUNT';
const initialState = {
  tabCreating: false,
  tabSwitching: false,
  tabRemoving: false,
  siderLoading: false,
  contentLayoutSwitched: true,
  tabLayout: {
    tabs: [],
    activeKey: null,
    newTabIndex: 0,
  },
  menuOptions: [],
  history: {},
  menuSelected: 'Dashboard',
  gridEditDetails: {},
  tourReferences: {},
};

function getSelectedMenuOption(tabs, key) {
  if (!(tabs.length - 1)) return initialState.menuSelected;

  const selectedTab = tabs.filter((data) => {
    return data.key === key;
  });

  if (selectedTab.length) return selectedTab[0].type;

  return initialState.menuSelected;
}

const calcGridEditCount = (state, action) => {
  if (action.countType === 'add') {
    return !state.gridEditDetails[action.tabKey]
      ? 1
      : state.gridEditDetails[action.tabKey]['gridEditCount'] + 1;
  } else if (
    action.countType === 'reduce' &&
    state.gridEditDetails[action.tabKey]['gridEditCount'] > 0
  ) {
    return state.gridEditDetails[action.tabKey]['gridEditCount'] - 1;
  } else if (action.countType === 'reset') {
    return 0;
  }
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_TAB:
      return { ...state, tabCreating: true };
    case CREATE_TAB_SUCCESS:
      return {
        ...state,
        tabLayout: {
          ...state.tabLayout,
          tabs: state.tabLayout.tabs.concat(action.payload.tabs),
          newTabIndex: action.payload.newTabIndex,
          activeKey: action.payload.activeKey,
        },
        menuSelected: action.payload.tabs.type,
        tabCreated: true,
        tabCreating: false,
      };
    case CREATE_TAB_FAIL:
      return { ...state, tabCreating: false, tabError: action.error };
    case SWITCH_TAB:
      return { ...state, tabSwitching: true };
    case SWITCH_TAB_SUCCESS:
      return {
        ...state,
        tabLayout: {
          ...state.tabLayout,
          activeKey: action.payload,
        },
        tabSwitching: false,
        tabSwitched: true,
        menuSelected: getSelectedMenuOption(
          state.tabLayout.tabs,
          action.payload
        ),
        error: null,
      };
    case SWITCH_TAB_FAIL:
      return { ...state, tabSwitching: false, error: action.error };
    case REMOVE_TAB:
      return { ...state, tabRemoving: true };
    case REMOVE_TAB_SUCCESS:
      return {
        ...state,
        tabLayout: {
          ...state.tabLayout,
          tabs: [
            ...state.tabLayout.tabs.filter((tab) => {
              return tab.key !== action.payload.targetKey;
            }),
          ],
          activeKey: action.payload.activeKey,
        },
        tabRemoving: false,
        menuSelected: getSelectedMenuOption(
          state.tabLayout.tabs,
          action.payload.activeKey
        ),
        tabRemoved: true,
        error: null,
      };
    case REMOVE_TAB_FAIL:
      return { ...state, tabRemoving: false, error: action.error };
    case SIDER:
      return {
        ...state,
        siderLoading: true,
        siderLoaded: false,
        menuOptions: [],
      };
    case SIDER_SUCCESS:
      return {
        ...state,
        menuOptions: action.payload,
        siderLoading: false,
        siderLoaded: true,
      };
    case SIDER_FAIL:
      return { ...state, siderLoading: false, error: action.error };
    case ADD_SIDER_OPTION_SUCCESS:
      return {
        ...state,
        menuOptions: [
          ...state.menuOptions.slice(0, action.insertPosition),
          action.menuOption,
          ...state.menuOptions.slice(action.insertPosition),
        ],
      };
    case REMOVE_SIDER_OPTION_SUCCESS:
      return {
        ...state,
        menuOptions: [
          ...state.menuOptions.filter((d) => d.name !== action.key),
        ],
      };
    case CLEAR_ALL_TABS:
      return {
        ...state,
        tabLayout: {
          tabs: [],
          activeKey: null,
          newTabIndex: 0,
        },
      };
    case SWITCH_CONTENT_LAYOUT_SUCCESS:
      return {
        ...state,
        contentLayoutSwitched: action.payload,
        tabLayout: {
          ...state.tabLayout,
          activeKey: null,
        },
        menuSelected: action.menuSelected,
      };
    case SET_NAVIGATE_OBJECT_SUCCESS:
      return {
        ...state,
        navigate: action.payload,
      };
    case SET_GRID_EDIT_COUNT:
      return {
        ...state,
        gridEditDetails: {
          ...state.gridEditDetails,
          [action.tabKey]: {
            ...state.gridEditDetails[action.tabKey],
            gridEditCount: calcGridEditCount(state, action),
          },
        },
      };
    default:
      return state;
  }
}
export function addTab(key, title, extraProps = {}) {
  return (dispatch, getState) => {
    // Create tab request
    dispatch({ type: CREATE_TAB });
    const { tabLayout } = getState().layout;
    let { newTabIndex } = tabLayout;
    let keyStrSplit = key.split('_');
    const activeKey = `newTab${newTabIndex++}`;

    let tabContent = keyStrSplit
      .map((str) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase())
      .join('');

    const { defaultAccess } = getState().authorization.initAuthorization;

    if (defaultAccess == 'CDS') {
      import(/* webpackChunkName: "cds" */ `../../../cds`).then((modules) => {
        /**
         * Dynamic report check
         * For dynamic report creation component will be directly added to tab content.
         */
        let tabComponent = modules[tabContent];
        if (extraProps.dynamicReport) {
          tabComponent = modules['CdsDynamicReport'];
        }
        dispatch(generateContent(tabComponent, activeKey, newTabIndex));
      });
    } else if (defaultAccess == 'PC') {
      // Set global search state to close
      dispatch(setSearchCloseProps({ closeQs: true, closeSs: true }));

      import(/* webpackChunkName: "pc" */ `../../../pc`).then((modules) => {
        dispatch(generateContent(modules[tabContent], activeKey, newTabIndex));
      });
    } else if (defaultAccess == 'UMS') {
      import(/* webpackChunkName: "ums" */ `../../../ums`).then((modules) => {
        dispatch(generateContent(modules[tabContent], activeKey, newTabIndex));
      });
    } else if (defaultAccess == 'SSP') {
      import(/* webpackChunkName: "ssp" */ `../../../ssp`).then((modules) => {
        dispatch(generateContent(modules[tabContent], activeKey, newTabIndex));
      });
    } else if (defaultAccess == 'ACP') {
      import(/* webpackChunkName: "acp" */ `../../../acp`).then((modules) => {
        dispatch(generateContent(modules[tabContent], activeKey, newTabIndex));
      });
    } else if (defaultAccess == 'EPS') {
      import(/* webpackChunkName: "eps" */ `../../../eps`).then((modules) => {
        dispatch(generateContent(modules[tabContent], activeKey, newTabIndex));
      });
    } else if (defaultAccess == 'CPT') {
      import(/* webpackChunkName: "cpt" */ `../../../cpt`).then((modules) => {
        dispatch(generateContent(modules[tabContent], activeKey, newTabIndex));
      });
    }
  };

  function generateContent(content, activeKey, newTabIndex) {
    return (dispatch) => {
      if (content !== undefined) {
        //Switch layout if it is in dashboard
        dispatch({ type: SWITCH_CONTENT_LAYOUT_SUCCESS, payload: false });
        //Creating tab content
        dispatch({
          type: CREATE_TAB_SUCCESS,
          payload: {
            tabs: {
              title: title,
              content,
              key: activeKey,
              type: key,
              ...extraProps,
            },
            newTabIndex,
            activeKey,
          },
        });

        // Reset global search state
        setTimeout(
          () =>
            dispatch(setSearchCloseProps({ closeQs: false, closeSs: false })),
          100
        );
      } else {
        dispatch(tabError());
      }
    };
  }

  function tabError() {
    message.error(`Requested module ${title} is not found!`);
    return {
      type: CREATE_TAB_FAIL,
      error: `Requested module ${title} is not found!`,
    };
  }
}

export function removeTab(targetKey) {
  return (dispatch, getState) => {
    const {
      tabLayout: { activeKey, tabs },
    } = getState().layout;
    let lastIndex,
      currentKey = activeKey;

    tabs.map((tab, i) => {
      if (tab.key === targetKey) {
        lastIndex = i - 1;
      }
    });

    const tabPanes = tabs.filter((pane) => pane.key !== targetKey);
    if (tabPanes.length && currentKey === targetKey) {
      if (lastIndex >= 0) {
        currentKey = tabPanes[lastIndex].key;
      } else {
        currentKey = tabPanes[0].key;
      }
    }

    if (!tabPanes.length) {
      dispatch({ type: SWITCH_CONTENT_LAYOUT_SUCCESS, payload: true });
    }

    dispatch({
      type: REMOVE_TAB_SUCCESS,
      payload: { activeKey: currentKey, targetKey },
    });
  };
}

export function getSiderOptions() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      const { defaultAccess } = getState().authorization.initAuthorization;
      dispatch({ type: SIDER });
      return api
        .post(
          defaultAccess.toLowerCase(),
          formatParams(
            {
              type: 'sider',
              details: {
                email: Cookies.get('email'),
              },
            },
            getState
          )
        )
        .then((response) => {
          let data = response.data;

          dispatch({ type: SIDER_SUCCESS, payload: data });
        });
    }
  };
}

export function destroyTabs() {
  return (dispatch) => {
    dispatch({ type: CLEAR_ALL_TABS });
    dispatch({
      type: SWITCH_CONTENT_LAYOUT_SUCCESS,
      payload: true,
      menuSelected: initialState.menuSelected,
    });
  };
}

export function setNavigateObject(navigate) {
  return {
    type: SET_NAVIGATE_OBJECT_SUCCESS,
    payload: navigate,
  };
}

export function addSiderOption(menuOption, insertPosition) {
  return {
    type: ADD_SIDER_OPTION_SUCCESS,
    menuOption,
    insertPosition,
  };
}

export function removeSiderOption(key) {
  return {
    type: REMOVE_SIDER_OPTION_SUCCESS,
    key,
  };
}

export function setGridEditCount({ countType, tabKey }) {
  return {
    type: SET_GRID_EDIT_COUNT,
    countType,
    tabKey,
  };
}
