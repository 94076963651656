import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Input,
  Col,
  Row,
  Select,
  Tabs,
  Modal,
  Spin,
  Button,
  message,
} from 'antd';
import { getLangTranslationList, updateProfile } from './redux/modules/profile';
import MfaSettings from './MfaSettings';
import ChangePasswd from './ChangePasswd';
import PropTypes from 'prop-types';
import { shallowEqualCheck } from '../../helpers/utils';
import Tourprofile from '../tour/tourprofile';
import Tourprofilesanm from '../tour/tourprofilesanm';
import Cookie from 'js-cookie';
import { setTheme } from '../../redux/theme';

const { Option } = Select;
const { TabPane } = Tabs;

const Column = (props) => {
  return (
    <Col
      xs={props.col}
      sm={props.col}
      md={props.col}
      lg={props.col}
      xl={props.col}
      style={{ padding: '0 10px' }}>
      {props.children}
    </Col>
  );
};

Column.propTypes = {
  col: PropTypes.number,
  children: PropTypes.element,
};

const Profile = (props) => {
  const [showProfileSaveBtn, setShowProfileSaveBtn] = useState(true);
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const dispatch = useDispatch();

  const defaultAccess = useSelector(
    ({ authorization: { initAuthorization } }) =>
      initAuthorization.defaultAccess
  );
  const getWalk = useSelector(({ tour }) => tour.tourDetails);
  const walkProfile = defaultAccess ? getWalk[defaultAccess.toLowerCase()] : {};
  const { tourReferences } = useSelector(({ tour }) => ({
    tourReferences: tour.tourReferences,
  }));

  const {
    userDetails,
    userDetailLoading,
    dropDownDataLoading,
    profileUpdating,
    dropDownData: {
      languagePreference,
      timeZone,
      portalPreference,
      customerPreference,
      delimiterParameter,
      countryList,
      themePreference,
    },
  } = props;

  let dataFromProps = {
    country: userDetails.user_country,
    mobile: userDetails.user_mobile_number,
    lang: userDetails.preferred_lang,
    zone: userDetails.time_zone,
    access: userDetails.default_portal_access,
    delimiter: userDetails.export_delimiter,
    pref: userDetails.customer_preference,
  };

  useEffect(() => {
    dispatch(getLangTranslationList());
  }, []);

  const prefixSelector = (
    <Form.Item name='user_country_code' noStyle>
      <Select
        style={{ width: 90 }}
        placeholder='Code'
        showSearch
        filterOption={(input, lpOptn) => {
          return lpOptn.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        onChange={(value) => {
          setFieldsValue({ user_country_code: value.split(' ')[0] });
        }}>
        {countryList?.map((country) => {
          return (
            <Option
              key={country.dial_code}
              value={`${country.dial_code} ${country.name}`}></Option>
          );
        })}
      </Select>
    </Form.Item>
  );

  const countrySelected = (value) => {
    let countrySel = value;
    form.setFieldsValue({ user_country_code: countrySel.split('_')[1] });
  };

  const handleCreate = (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then((values) => {
        let newVal = {
          ...values,
          user_country: values.user_country.split('_')[0],
          user_mobile_number: `${values.user_mobile_number}`,
          key: 'phone_number',
          value: `${values.user_country_code}${values.user_mobile_number}`,
        };

        let dataFromForm = {
          country: newVal.user_country,
          mobile: newVal.user_mobile_number,
          lang: newVal.user_language,
          zone: newVal.time_zone,
          access: newVal.default_portal_access,
          delimiter: newVal.export_delimiter,
          pref: newVal.customer_preference,
        };

        const checkPreferredTheme = (data) =>
          data.label === values.customer_preference.toUpperCase();

        if (!shallowEqualCheck(dataFromProps, dataFromForm)) {
          dispatch(updateProfile(newVal)).then(() => {
            //Modify theme based on preference selection
            if (themePreference) {
              const theme = themePreference.find(checkPreferredTheme);
              if (Object.getOwnPropertyNames(theme).length > 0) {
                dispatch(setTheme(theme.value));
              }
            }

            props.handleVisibility();
          });
        } else {
          message.error('Update the Profile Details and Submit');
        }
      })
      .catch(() => {});
  };

  const isUserExternal = userDetails.user_type !== 'Sanmina';

  const handleProfileSave = (activeKey) => {
    if (activeKey === 'mfaSettings' || activeKey === 'changePassword') {
      setShowProfileSaveBtn(false);
    } else {
      setShowProfileSaveBtn(true);
    }
  };
  const showSanmWalkthrough = (walkthroughProfileWalk) =>
    userDetails.email &&
    props.countP &&
    props.showWalkthrough &&
    (!walkProfile ||
      (walkProfile && walkProfile.profile == undefined) ||
      (walkProfile !== undefined && walkProfile.profile) ||
      (walkthroughProfileWalk === false && walkthroughProfileWalk == 'Yes'));

  const showCognitoWalkthrough = (walkthroughProfileWalk) =>
    userDetails.email &&
    props.countP &&
    props.showWalkthrough &&
    userDetails.email.indexOf('sanmina') == -1 &&
    tourReferences.profileEnable.current != undefined &&
    (!walkProfile ||
      (walkProfile && walkProfile.profile == undefined) ||
      (walkProfile !== undefined && walkProfile.profile) ||
      (walkthroughProfileWalk === false && walkthroughProfileWalk == 'Yes'));

  const showWalkthrough = () => {
    const walkthroughProfileWalk = Cookie.get('walkthroughProfileWalk');
    if (showCognitoWalkthrough(walkthroughProfileWalk)) {
      return <Tourprofile />;
    } else if (showSanmWalkthrough(walkthroughProfileWalk)) {
      return <Tourprofilesanm />;
    }
  };

  const changeLanguage = (lang) => {
    setFieldsValue({ user_language: lang });
  };

  const handlePrefChange = (value) => {
    setFieldsValue({ customer_preference: value });
  };

  return (
    <Modal
      centered
      open={props.visible}
      title={props.title}
      onCancel={props.onCancel}
      onOk={handleCreate}
      width={1000}
      footer={[
        <Button key='cancel' onClick={props.onCancel}>
          Cancel
        </Button>,
        <>
          {showProfileSaveBtn && (
            <Button key='save' type='primary' onClick={handleCreate}>
              {props.okText}
            </Button>
          )}
        </>,
      ]}
      className='profile'
      icon='<ExclamationCircleOutlined />'>
      <Spin
        spinning={userDetailLoading || dropDownDataLoading || profileUpdating}
        delay={500}>
        <Tabs
          defaultActiveKey='profileDetails'
          type='card'
          onChange={handleProfileSave}>
          <TabPane
            tab='User Details'
            key='profileDetails'
            className='profile-user-details'>
            <Form
              layout='vertical'
              form={form}
              initialValues={{
                first_name: userDetails.first_name,
                last_name: userDetails.last_name,
                email: userDetails.email,
                user_country: userDetails.user_country,
                user_country_code: userDetails.user_country_code,
                user_mobile_number: userDetails.user_mobile_number,
                user_language: userDetails.preferred_lang,
                time_zone: userDetails.time_zone,
                default_portal_access: userDetails.default_portal_access,
                customer_preference: userDetails.customer_preference,
                export_delimiter: userDetails.export_delimiter,
              }}>
              <Row>
                {showWalkthrough()}
                <Column col={12}>
                  <Form.Item
                    name='first_name'
                    rules={[
                      {
                        message: 'Please input your first name!',
                      },
                    ]}
                    label='First Name'>
                    <Input
                      placeholder='Enter First Name'
                      disabled
                      onChange={(e) => {
                        setFieldsValue({ first_name: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Column>

                <Column col={12}>
                  <Form.Item
                    label='Last Name'
                    name='last_name'
                    rules={[
                      {
                        message: 'Please input your last name!',
                      },
                    ]}>
                    <Input
                      placeholder='Enter Last Name'
                      disabled
                      onChange={(e) => {
                        setFieldsValue({ last_name: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Column>
              </Row>

              <Row>
                <Column col={12}>
                  <Form.Item
                    label='E-mail'
                    name='email'
                    rules={[{ message: 'Please input your email!' }]}>
                    <Input
                      placeholder='Enter E-mail'
                      onChange={(e) => {
                        setFieldsValue({ email: e.target.value });
                      }}
                      disabled
                    />
                  </Form.Item>
                </Column>

                <Column col={12}>
                  <Form.Item
                    label='Country'
                    name='user_country'
                    rules={[
                      { required: true, message: 'Please input your Country!' },
                    ]}>
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        countrySelected(value);
                        setFieldsValue({ user_country: value });
                      }}
                      placeholder='Select a Country'>
                      {countryList?.map((countries) => {
                        return (
                          <Option
                            key={countries.name}
                            value={`${countries.name}_${countries.dial_code}`}>{`${countries.name}`}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Column>
              </Row>

              <Row>
                <Column col={12}>
                  <Form.Item
                    name='user_mobile_number'
                    label='Phone'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your phone number!',
                      },
                    ]}>
                    <Input
                      addonBefore={prefixSelector}
                      style={{ width: '100%' }}
                      placeholder='Enter Mobile Number'
                      type='number'
                    />
                  </Form.Item>
                </Column>
                <Column col={12}>
                  <span ref={tourReferences.profileDelimiter}>
                    <Form.Item
                      label='Delimiter Parameter'
                      className='sanm-delimeter'
                      name='export_delimiter'
                      rules={[
                        {
                          required: true,
                          message: 'Please input Delimiter Parameter!',
                        },
                      ]}>
                      <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, dpOptn) =>
                          dpOptn.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          setFieldsValue({ export_delimiter: value });
                        }}
                        placeholder='Select a Delimiter Parameter'>
                        {delimiterParameter?.map((delimiter) => {
                          return (
                            <Option
                              key={delimiter.name}
                              value={`${delimiter.value}`}>{`${delimiter.name}`}</Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </span>
                </Column>
                <Column col={24}>
                  <Form.Item
                    label='Language Preference'
                    name='user_language'
                    rules={[
                      {
                        required: true,
                        message: 'Please input Language Preference!',
                      },
                    ]}>
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, lpOptn) =>
                        lpOptn.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => changeLanguage(value)}
                      placeholder='Select Your Language Preference'>
                      {languagePreference?.map((languagePref) => {
                        return (
                          <Option
                            key={languagePref.value}
                            value={`${languagePref.value}`}>{`${languagePref.label}`}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Column>
              </Row>

              <Row>
                <Column col={24}>
                  <Form.Item
                    label='Time Zone'
                    name='time_zone'
                    rules={[
                      { required: true, message: 'Please input Time Zone!' },
                    ]}>
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, tzOptn) =>
                        tzOptn.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        setFieldsValue({ time_zone: value });
                      }}
                      placeholder='Select a Time Zone'>
                      {timeZone?.map((zone) => {
                        return (
                          <Option
                            key={zone.name}
                            value={`${zone.value}`}>{`${zone.name}`}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Column>
              </Row>

              <Row>
                <Column col={12}>
                  <Form.Item
                    label='Portal Preference'
                    name='default_portal_access'
                    rules={[{ message: 'Please input Portal Preference!' }]}>
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, pfOptn) =>
                        pfOptn.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        setFieldsValue({ default_portal_access: value });
                      }}
                      placeholder='Select Your Portal Preference'>
                      {portalPreference?.map((portalPref) => {
                        return (
                          <Option
                            key={portalPref.access}
                            value={
                              portalPref.access
                            }>{`${portalPref.access}`}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Column>
                <Column col={12}>
                  <Form.Item
                    label='Customer Preference'
                    name='customer_preference'
                    rules={[
                      {
                        message: 'Please input Customer Preference!',
                      },
                    ]}>
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, cpOptn) =>
                        cpOptn.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={handlePrefChange}
                      placeholder='Select Your Customer Preference!'>
                      {customerPreference?.map((customerPref) => {
                        return (
                          <Option
                            key={customerPref}
                            value={`${customerPref}`.toLowerCase()}>{`${customerPref}`}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Column>
              </Row>
              <div className='clearfix'></div>
            </Form>
          </TabPane>
          {isUserExternal ? (
            <>
              <TabPane
                tab={
                  <span ref={tourReferences.profileEnable}>{'Two Factor'}</span>
                }
                key='mfaSettings'
                className='mfa-settings'>
                <MfaSettings {...props}></MfaSettings>
              </TabPane>
              <TabPane
                tab={
                  <span ref={tourReferences.profilePassword}>
                    {'Change Password'}
                  </span>
                }
                key='changePassword'
                className='change-password'>
                <ChangePasswd />
              </TabPane>
            </>
          ) : null}
        </Tabs>
      </Spin>
    </Modal>
  );
};

Profile.propTypes = {
  languagePreference: PropTypes.object,
  timeZone: PropTypes.object,
  portalPreference: PropTypes.object,
  customerPreference: PropTypes.object,
  delimiterParameter: PropTypes.object,
  countryList: PropTypes.object,
  userDetails: PropTypes.object,
  userDetailLoading: PropTypes.bool,
  dropDownDataLoading: PropTypes.bool,
  profileUpdating: PropTypes.bool,
  dropDownData: PropTypes.object,
  handleVisibility: PropTypes.func,
  countP: PropTypes.any,
  showWalkthrough: PropTypes.any,
  visible: PropTypes.bool,
  title: PropTypes.any,
  onCancel: PropTypes.func,
  okText: PropTypes.string,
};

export default Profile;
