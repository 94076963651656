import store from '../../../../redux/store';
import service from '../../../../service/Service';
import {
  getLastRowIndex,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../helpers/utils';
import Cookie from 'js-cookie';
import { exportDTo, getMassDownloadGridParams } from 'helpers/export';
export const ORDER_HISTORY_COLUMNS = 'cds/order-history/ORDER_HISTORY_COLUMNS';
export const ORDER_HISTORY_COLUMNS_SUCCESS =
  'cds/order-history/ORDER_HISTORY_COLUMNS_SUCCESS';
export const ORDER_HISTORY_COLUMNS_FAIL =
  'cds/order-history/ORDER_HISTORY_COLUMNS_FAIL';
export const ORDER_HISTORY_ROWS_INFO =
  'cds/order-history/ORDER_HISTORY_ROWS_INFO';

const initialState = {
  newTab0: {
    loading: false,
    rowsInfo: {
      endRow: 0,
      lastRow: 0,
    },
    orderHistoryColumns: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ORDER_HISTORY_COLUMNS:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], loading: true },
      };
    case ORDER_HISTORY_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          orderHistoryColumns: action.payload,
          loaded: true,
        },
      };
    case ORDER_HISTORY_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
          loaded: true,
        },
      };
    case ORDER_HISTORY_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          rowsInfo: action.payload,
        },
      };
    default:
      return state;
  }
}

function getOrderHistoryColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: ORDER_HISTORY_COLUMNS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `ORDER_STATUS_HEADER_HIST_V`,
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/CDS/GET_ORDER_STATUS_HEADER_HIST_V_COLUMNS`,
          currentTime
        );
        const { data } = response;
        if (data && data.statusCode === '200') {
          let configs = data.result;
          dispatch({
            type: ORDER_HISTORY_COLUMNS_SUCCESS,
            payload: configs,
            tabKey,
          });
        } else {
          dispatch({
            type: ORDER_HISTORY_COLUMNS_FAIL,
            payload: 'Error in loading fc form!',
            tabKey,
          });
        }
      });
  };
}

function getOrderHistoryRows(tabKey, details) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: `ORDER_STATUS_HEADER_HIST_V`,
              gridParams: { ...params.request },
              details: {
                email: Cookie.get('email'),
                order_status:
                  details.orderStatus != undefined ? details.orderStatus : '',
                order_duration:
                  details.orderDuration != undefined
                    ? details.orderDuration
                    : '',
                screen: 'orders',
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CDS/GET_ORDER_STATUS_HEADER_HIST_V_ROWS`,
            currentTime
          );
          const { data } = response;
          if (data && data.statusCode === '200') {
            params.success({
              rowData: data.result.rowData,
              rowCount: getLastRowIndex(params.request, data.result.rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: ORDER_HISTORY_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.result.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
  orderStatus,
  orderDuration,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: Cookie.get('email'),
              details: {
                email: Cookie.get('email'),
                order_status: orderStatus,
                order_duration: orderDuration,
                screen: 'orders',
              },
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: Cookie.get('email'),
                order_status: orderStatus,
                order_duration: orderDuration,
                screen: 'orders',
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CDS/GET_${view}_MASS_DOWNLOAD_REPORT`,
            currentTime
          );
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              exportDTo({
                columnApi,
                fileName: fileName,
                data: result.rowData,
                exportType,
              }) && cb(true);
            }
          }
          return response;
        });
    }
  };
}

export { getOrderHistoryColumns, getOrderHistoryRows };
