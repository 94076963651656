import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const CustomHeaderCheckboxRenderer = (props) => {
  const smiObj = useSelector(({ pc }) => pc.smi);
  const smiTabObj = smiObj[props.tabKey] || {};
  const filterOrSortChanged = smiTabObj?.resetHeaderCheckbox;

  const getTotalRowCount = () => {
    let totalRows = 0;
    props.api.forEachNode((_node) => totalRows++);
    return totalRows;
  };

  const [selected, setSelected] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  useEffect(() => {
    const selectedNodes = props.api.getSelectedNodes().length;
    const allNodesInView = getTotalRowCount();
    if (
      selectedNodes &&
      selectedNodes % parseInt(process.env.REACT_APP_AG_GRID_ROWS_LIMIT) == 0 &&
      selectedNodes >= allNodesInView
    ) {
      setSelected(true);
      setIndeterminate(false);
    } else if (selectedNodes > 0) {
      setSelected(false);
      setIndeterminate(true);
    }
  }, []);

  useEffect(() => {
    if (filterOrSortChanged) {
      setSelected(false);
      setIndeterminate(false);
    }
  }, [filterOrSortChanged]);

  const handleClick = (event) => {
    setSelected(event.target.checked);
    setIndeterminate(false);
    props.api.forEachNode((node) => {
      if (node.data.editable) {
        node.setSelected(event.target.checked);
      }
    });
    props.api.refreshCells({ force: true, columns: ['select'] });
  };

  return (
    <Checkbox
      checked={selected}
      onClick={handleClick}
      indeterminate={indeterminate}
    />
  );
};

CustomHeaderCheckboxRenderer.propTypes = {
  api: PropTypes.object,
};

export default CustomHeaderCheckboxRenderer;
