import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { Select } from 'antd';

dayjs.extend(weekday);
dayjs.extend(localeData);

const { Option } = Select;

const getObjectValue = (obj, key) => {
  return obj?.[key] || {};
};

const focusTextCell = (screenData, colId, rowIndex, refInput) => {
  if (
    screenData.focusedCell &&
    colId === screenData.focusedCell.colId &&
    rowIndex === screenData.focusedCell.rowIndex &&
    refInput?.current
  ) {
    refInput.current.focus();
  }
};

const refreshMasterRow = (originalId, dupeId, props) => {
  if (!props.node.data.group) {
    props.node.parent.setSelected(true);
    props.node.parent.updateData({ ...props.node.parent.data, id: dupeId });
    setTimeout(() => {
      props.node.parent.updateData({
        ...props.node.parent.data,
        id: originalId,
      });
    }, 0);
  }
};

const refreshRow = (props) => {
  const originalId = props.node.data.id;
  const dupeId = 'test';

  props.node.updateData({ ...props.node.data, id: dupeId });
  setTimeout(() => {
    props.node.updateData({ ...props.node.data, id: originalId });
  }, 0);

  refreshMasterRow(originalId, dupeId, props);
};

const handleRefreshOnValueChange = (props) => {
  if (!props.node.isSelected()) {
    props.node.setSelected(true);
    refreshRow(props);
    props.api.refreshHeader();
  }
};

const getLovData = (moduleData, module) => moduleData[`${module}Lov`] || {};

const getHolidays = (lovList, props) =>
  lovList['holidays']
    ? lovList['holidays'][props.node.data.customer_plant]
    : [];

const getFormattedDate = (val) => (val ? dayjs(val, 'YYYY-MM-DD') : '');

const getFormattedDateStr = (date) =>
  date ? dayjs(date).format('YYYY-MM-DD') : '';

const isPastDate = (current) => {
  return current < dayjs().add(-1, 'day');
};

const isPlantHoliday = (current, holidays) =>
  holidays?.includes(getFormattedDateStr(current));

const isWeekend = (current) =>
  dayjs(current).day() === 0 || dayjs(current).day() === 6;

const isCrossedHorizonEndDate = (current, horizonEndDate) =>
  dayjs(current) > horizonEndDate;

const getDisabledDates = (current, holidays, horizonEndDate) => {
  return (
    isPastDate(current) ||
    isWeekend(current) ||
    isPlantHoliday(current, holidays) ||
    isCrossedHorizonEndDate(current, horizonEndDate)
  );
};

const getMandatoryClass = (requiredColumns, colId) => {
  return requiredColumns?.includes(colId) ? 'om-mandatory-col' : '';
};

const getErrorDesc = (errorDesc) => (
  <div style={{ fontSize: 12 }}>{errorDesc}</div>
);

const getLovList = (lovList, colId, props) =>
  lovList[colId] ? lovList[colId][props.node.data.customer_plant] : [];

const getMandatoryClassSelect = (requiredColumns, colId) => {
  return requiredColumns?.includes(colId) ? 'om-mandatory-select-col' : '';
};

const showLovList = (lov) =>
  lov?.length
    ? lov.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))
    : null;

const getLovListValue = (lovList, colId) =>
  lovList[colId] ? lovList[colId] : [];

const getDefaultValue = (val) =>
  val !== '' && val !== 'Cancel' ? getFormattedDate(val) : '';

const isParentEditable = (props, colId, parentExcludeEditable) =>
  props.node.data.group && !parentExcludeEditable.includes(colId);

const isLeafEditable = (props, colId, leafEditables) =>
  !props.node.data.group && leafEditables.includes(colId);

const focusDatePickerCell = ({
  screenData,
  colId,
  rowIndex,
  refDatePicker,
  callBack,
}) => {
  if (
    screenData.focusedCell &&
    colId === screenData.focusedCell.colId &&
    rowIndex === screenData.focusedCell.rowIndex &&
    refDatePicker?.current
  ) {
    refDatePicker.current.focus();
    callBack(true);
  }
};

const isCellHasErrors = (errors, colId) =>
  errors && errors[colId] !== undefined;

export {
  getObjectValue,
  focusTextCell,
  handleRefreshOnValueChange,
  getLovData,
  getHolidays,
  getFormattedDate,
  isPastDate,
  getDisabledDates,
  getMandatoryClass,
  getErrorDesc,
  getLovList,
  getMandatoryClassSelect,
  showLovList,
  getLovListValue,
  getDefaultValue,
  isParentEditable,
  isLeafEditable,
  focusDatePickerCell,
  isCellHasErrors,
  getFormattedDateStr,
};
